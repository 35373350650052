/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.addJSON({
  bcl: {
    nested: {
      TimeSpan: {
        oneofs: {
          _value: {
            oneof: [
              "value"
            ]
          },
          _scale: {
            oneof: [
              "scale"
            ]
          }
        },
        fields: {
          value: {
            type: "sint64",
            id: 1,
            options: {
              proto3_optional: true
            }
          },
          scale: {
            type: "TimeSpanScale",
            id: 2,
            options: {
              "default": "DAYS",
              proto3_optional: true
            }
          }
        },
        nested: {
          TimeSpanScale: {
            values: {
              DAYS: 0,
              HOURS: 1,
              MINUTES: 2,
              SECONDS: 3,
              MILLISECONDS: 4,
              TICKS: 5,
              MINMAX: 15
            }
          }
        }
      },
      DateTime: {
        oneofs: {
          _value: {
            oneof: [
              "value"
            ]
          },
          _scale: {
            oneof: [
              "scale"
            ]
          }
        },
        fields: {
          value: {
            type: "sint64",
            id: 1,
            options: {
              proto3_optional: true
            }
          },
          scale: {
            type: "TimeSpanScale",
            id: 2,
            options: {
              "default": "DAYS",
              proto3_optional: true
            }
          }
        },
        nested: {
          TimeSpanScale: {
            values: {
              DAYS: 0,
              HOURS: 1,
              MINUTES: 2,
              SECONDS: 3,
              MILLISECONDS: 4,
              TICKS: 5,
              MINMAX: 15
            }
          }
        }
      },
      Guid: {
        oneofs: {
          _lo: {
            oneof: [
              "lo"
            ]
          },
          _hi: {
            oneof: [
              "hi"
            ]
          }
        },
        fields: {
          lo: {
            type: "fixed64",
            id: 1,
            options: {
              proto3_optional: true
            }
          },
          hi: {
            type: "fixed64",
            id: 2,
            options: {
              proto3_optional: true
            }
          }
        }
      },
      Decimal: {
        oneofs: {
          _lo: {
            oneof: [
              "lo"
            ]
          },
          _hi: {
            oneof: [
              "hi"
            ]
          },
          _signScale: {
            oneof: [
              "signScale"
            ]
          }
        },
        fields: {
          lo: {
            type: "uint64",
            id: 1,
            options: {
              proto3_optional: true
            }
          },
          hi: {
            type: "uint32",
            id: 2,
            options: {
              proto3_optional: true
            }
          },
          signScale: {
            type: "sint32",
            id: 3,
            options: {
              proto3_optional: true
            }
          }
        }
      }
    }
  },
  ALLRHGWStatus: {
    nested: {
      IOTAirSwitchModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          DevType: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          IP: {
            type: "string",
            id: 4
          },
          Port: {
            type: "int32",
            id: 5
          },
          Name: {
            type: "string",
            id: 6
          },
          Address: {
            type: "int32",
            id: 7
          },
          States: {
            type: "int32",
            id: 8
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 9
          },
          OnLine: {
            type: "bool",
            id: 10
          }
        }
      },
      IOTAmmeterModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          DevType: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          IP: {
            type: "string",
            id: 4
          },
          Name: {
            type: "string",
            id: 5
          },
          Address: {
            type: "int32",
            id: 6
          },
          Phase: {
            type: "int32",
            id: 7
          },
          InitCharge: {
            type: "float",
            id: 8
          },
          Voltage: {
            rule: "repeated",
            type: "float",
            id: 9,
            options: {
              packed: false
            }
          },
          ElectricCurrent: {
            rule: "repeated",
            type: "float",
            id: 10,
            options: {
              packed: false
            }
          },
          TotalCurrent: {
            type: "float",
            id: 11
          },
          KW: {
            rule: "repeated",
            type: "float",
            id: 12,
            options: {
              packed: false
            }
          },
          TotalKW: {
            type: "float",
            id: 13
          },
          ReactivePower: {
            rule: "repeated",
            type: "float",
            id: 14,
            options: {
              packed: false
            }
          },
          PowerFactor: {
            rule: "repeated",
            type: "float",
            id: 15,
            options: {
              packed: false
            }
          },
          Frequency: {
            type: "float",
            id: 16
          },
          KWH: {
            type: "float",
            id: 17
          },
          kvarh: {
            type: "float",
            id: 18
          },
          PowerWarn: {
            type: "int32",
            id: 19
          },
          EnergyWarnValue: {
            type: "double",
            id: 20
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 21
          },
          OnLine: {
            type: "bool",
            id: 22
          }
        }
      },
      IOTGWModel: {
        fields: {
          IP: {
            type: "string",
            id: 1
          },
          MAC: {
            type: "string",
            id: 2
          },
          ID: {
            type: "string",
            id: 3
          },
          DeviceID: {
            type: "string",
            id: 4
          },
          ClsRoomID: {
            type: "string",
            id: 5
          },
          ClsRoomName: {
            type: "string",
            id: 6
          },
          SceneNum: {
            type: "int32",
            id: 7
          },
          DimWhite: {
            type: "int32",
            id: 8
          },
          DimYellow: {
            type: "int32",
            id: 9
          },
          State: {
            type: "bool",
            id: 10
          },
          DeviceList: {
            rule: "repeated",
            type: "IoTDevice",
            id: 11
          },
          IOTSensorModel: {
            type: "IOTSensorModel",
            id: 12
          },
          IOTAmmeterModels: {
            rule: "repeated",
            type: "IOTAmmeterModel",
            id: 13
          },
          IOTAirSwitchModels: {
            rule: "repeated",
            type: "IOTAirSwitchModel",
            id: 14
          }
        }
      },
      IOTSensorModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          DevType: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          SensorIP: {
            type: "string",
            id: 4
          },
          SensorChannel: {
            type: "int32",
            id: 5
          },
          SensorHumidity: {
            type: "double",
            id: 6
          },
          SensorHumidityLevel: {
            type: "int32",
            id: 7
          },
          SensorHumidityLevelName: {
            type: "string",
            id: 8
          },
          SensorTemperature: {
            type: "double",
            id: 9
          },
          SensorTemperatureLevel: {
            type: "int32",
            id: 10
          },
          SensorTemperatureLevelName: {
            type: "string",
            id: 11
          },
          SensorLUX: {
            type: "int32",
            id: 12
          },
          SensorLUXLevel: {
            type: "int32",
            id: 13
          },
          SensorLUXLevelName: {
            type: "string",
            id: 14
          },
          SensorPM25: {
            type: "int32",
            id: 15
          },
          SensorPM25Level: {
            type: "int32",
            id: 16
          },
          SensorPM25LevelName: {
            type: "string",
            id: 17
          },
          SensorPM10: {
            type: "int32",
            id: 18
          },
          SensorPM10Level: {
            type: "int32",
            id: 19
          },
          SensorPM10LevelName: {
            type: "string",
            id: 20
          },
          SensorCO2: {
            type: "int32",
            id: 21
          },
          SensorCO2Level: {
            type: "int32",
            id: 22
          },
          SensorCO2LevelName: {
            type: "string",
            id: 23
          },
          SensorCH2O: {
            type: "double",
            id: 24
          },
          SensorCH2OLevel: {
            type: "int32",
            id: 25
          },
          SensorCH2OLevelName: {
            type: "string",
            id: 26
          },
          SensorTVOC: {
            type: "double",
            id: 27
          },
          SensorTVOCLevel: {
            type: "int32",
            id: 28
          },
          SensorTVOCLevelName: {
            type: "string",
            id: 29
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 30
          },
          OnLine: {
            type: "bool",
            id: 31
          }
        }
      },
      IoTDevice: {
        fields: {
          IP: {
            type: "string",
            id: 1
          },
          ID: {
            type: "string",
            id: 2
          },
          Type: {
            type: "string",
            id: 3
          },
          State: {
            type: "bool",
            id: 4
          },
          on_1: {
            type: "bool",
            id: 5
          },
          on_2: {
            type: "bool",
            id: 6
          },
          on_3: {
            type: "bool",
            id: 7
          },
          white: {
            type: "int32",
            id: 8
          },
          yellow: {
            type: "int32",
            id: 9
          },
          MotorState: {
            type: "int32",
            id: 10
          },
          FanState: {
            type: "int32",
            id: 11
          },
          VPStates: {
            rule: "repeated",
            type: "IoTVPState",
            id: 12
          },
          IOTGWIP: {
            type: "string",
            id: 14
          },
          DoorSensorOpen: {
            type: "bool",
            id: 15
          },
          DoorSensorAbnormal: {
            type: "bool",
            id: 16
          },
          DoorLockNO: {
            type: "bool",
            id: 17
          },
          DoorLockSwitch: {
            type: "bool",
            id: 18
          },
          Battery: {
            type: "int32",
            id: 19
          }
        }
      },
      IoTVPState: {
        fields: {
          ID: {
            type: "string",
            id: 1
          },
          B0: {
            type: "int32",
            id: 2
          },
          B1: {
            type: "int32",
            id: 3
          },
          B2: {
            type: "int32",
            id: 4
          },
          B3: {
            type: "int32",
            id: 5
          },
          B4: {
            type: "int32",
            id: 6
          },
          B6: {
            type: "int32",
            id: 7
          }
        }
      }
    }
  },
  RBModel: {
    nested: {
      AssetType: {
        fields: {
          ATID: {
            type: "string",
            id: 1
          },
          ATParentID: {
            type: "string",
            id: 2
          },
          ATName: {
            type: "string",
            id: 3
          },
          ATCode: {
            type: "string",
            id: 4
          },
          ATShow: {
            type: "int32",
            id: 5
          },
          ATOrder: {
            type: "int32",
            id: 6
          },
          ATDescription: {
            type: "string",
            id: 7
          },
          ATEstate: {
            type: "int32",
            id: 8
          },
          ParentAssetType: {
            type: "AssetType",
            id: 10
          }
        }
      },
      Brand: {
        fields: {
          BrandID: {
            type: "string",
            id: 1
          },
          BrandCode: {
            type: "string",
            id: 2
          },
          BrandName: {
            type: "string",
            id: 3
          },
          BrandPriority: {
            type: "int32",
            id: 4
          },
          BrandDescription: {
            type: "string",
            id: 5
          },
          BrandEstate: {
            type: "int32",
            id: 6
          },
          BrandCreateDate: {
            type: ".bcl.DateTime",
            id: 7
          },
          BrandUpdateDate: {
            type: ".bcl.DateTime",
            id: 8
          }
        }
      },
      Building: {
        fields: {
          BuildingID: {
            type: "string",
            id: 1
          },
          SchoolID: {
            type: "string",
            id: 2
          },
          CampusesID: {
            type: "string",
            id: 3
          },
          BuildingName: {
            type: "string",
            id: 4
          },
          BuildingHouseProperty: {
            type: "string",
            id: 5
          },
          BuildingUseStatus: {
            type: "string",
            id: 6
          },
          BuildingClassify: {
            type: "string",
            id: 7
          },
          BuildingStructure: {
            type: "string",
            id: 8
          },
          BuildingFloors: {
            type: "int32",
            id: 9
          },
          BuildingYears: {
            type: "string",
            id: 10
          },
          BuildingTotalInvestment: {
            type: ".bcl.Decimal",
            id: 11
          },
          BuildingFundsSource: {
            type: "string",
            id: 12
          },
          BuildingArea: {
            type: ".bcl.Decimal",
            id: 13
          },
          BulidingUseArea: {
            type: ".bcl.Decimal",
            id: 14
          },
          BuildingKZSFLDM: {
            type: "string",
            id: 15
          },
          BuildingKZSFBZM: {
            type: "string",
            id: 16
          },
          BuildingAddr: {
            type: "string",
            id: 17
          },
          BuildingCondition: {
            type: "string",
            id: 18
          },
          BuildingPic: {
            type: "string",
            id: 19
          },
          BuildingLayout: {
            type: "string",
            id: 20
          },
          BuildingOwnershipNO: {
            type: "string",
            id: 21
          },
          BuildingFloorage: {
            type: ".bcl.Decimal",
            id: 22
          },
          BuildingServiceLife: {
            type: "int32",
            id: 23
          },
          BuildingDescription: {
            type: "string",
            id: 24
          },
          BuildingEstate: {
            type: "int32",
            id: 25
          },
          BuildingCreateDate: {
            type: ".bcl.DateTime",
            id: 26
          },
          BuildingUpdateDate: {
            type: ".bcl.DateTime",
            id: 27
          },
          BuildingPriority: {
            type: "int32",
            id: 28
          },
          OriID: {
            type: "string",
            id: 29
          },
          Campuses: {
            type: "Campuses",
            id: 32
          }
        }
      },
      Campuses: {
        fields: {
          CampusesID: {
            type: "string",
            id: 1
          },
          SchoolID: {
            type: "string",
            id: 2
          },
          CampusesName: {
            type: "string",
            id: 3
          },
          CampusesAddr: {
            type: "string",
            id: 4
          },
          CampusesPostCode: {
            type: "string",
            id: 5
          },
          CampusesTEL: {
            type: "string",
            id: 6
          },
          CampusesFax: {
            type: "string",
            id: 7
          },
          CampusesFZRH: {
            type: "string",
            id: 8
          },
          CampusesEstate: {
            type: "int32",
            id: 9
          },
          CampusesCreateDate: {
            type: ".bcl.DateTime",
            id: 10
          },
          CampusesUpdateDate: {
            type: ".bcl.DateTime",
            id: 11
          },
          CampusesMail: {
            type: "string",
            id: 12
          },
          CampusesSZDXZQHM: {
            type: "string",
            id: 13
          },
          CampusesAcreage: {
            type: "int64",
            id: 14
          },
          CampusesBuildAcreage: {
            type: "int64",
            id: 15
          },
          CampusesJXKYSBZZ: {
            type: ".bcl.Decimal",
            id: 16
          },
          CampusesGDZCZZ: {
            type: ".bcl.Decimal",
            id: 17
          },
          CampusesPriority: {
            type: "int32",
            id: 18
          },
          OriID: {
            type: "string",
            id: 19
          },
          School: {
            type: "School",
            id: 21
          }
        }
      },
      ClsRoom: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          SchoolID: {
            type: "string",
            id: 2
          },
          CampusesID: {
            type: "string",
            id: 3
          },
          BuildingID: {
            type: "string",
            id: 4
          },
          DeptID: {
            type: "string",
            id: 5
          },
          ClsRoomName: {
            type: "string",
            id: 6
          },
          ClsRoomFloor: {
            type: "int32",
            id: 7
          },
          ClsRoomUse: {
            type: "string",
            id: 8
          },
          ClsRoomSeats: {
            type: "int32",
            id: 9
          },
          ClsRoomEffectiveSeats: {
            type: "int32",
            id: 10
          },
          ClsRoomExamSeats: {
            type: "int32",
            id: 11
          },
          ClsRoomType: {
            type: "string",
            id: 12
          },
          ClsRoomNetworkState: {
            type: "int32",
            id: 13
          },
          ClsRoomDescription: {
            type: "string",
            id: 14
          },
          ClsRoomEstate: {
            type: "int32",
            id: 15
          },
          ClsRoomCreateDate: {
            type: ".bcl.DateTime",
            id: 16
          },
          ClsRoomUpdateDate: {
            type: ".bcl.DateTime",
            id: 17
          },
          RoomArea: {
            type: ".bcl.Decimal",
            id: 18
          },
          RoomUseArea: {
            type: ".bcl.Decimal",
            id: 19
          },
          RoomPlan: {
            type: "string",
            id: 20
          },
          ClsRoomPriority: {
            type: "int32",
            id: 21
          },
          OriID: {
            type: "string",
            id: 22
          },
          Campuses: {
            type: "Campuses",
            id: 25
          },
          Building: {
            type: "Building",
            id: 26
          },
          Department: {
            type: "Department",
            id: 27
          }
        }
      },
      Department: {
        fields: {
          DeptID: {
            type: "string",
            id: 1
          },
          SchoolID: {
            type: "string",
            id: 2
          },
          DeptMemberOf: {
            type: "string",
            id: 3
          },
          CampusesID: {
            type: "string",
            id: 4
          },
          DeptName: {
            type: "string",
            id: 5
          },
          DeptEnName: {
            type: "string",
            id: 6
          },
          DeptShortName: {
            type: "string",
            id: 7
          },
          DeptEnShortName: {
            type: "string",
            id: 8
          },
          DeptJP: {
            type: "string",
            id: 9
          },
          DeptTel: {
            type: "string",
            id: 10
          },
          DeptCategory: {
            type: "string",
            id: 11
          },
          DeptBBM: {
            type: "string",
            id: 12
          },
          DeptExpirationDate: {
            type: ".bcl.DateTime",
            id: 13
          },
          DeptIsEntity: {
            type: "int32",
            id: 14
          },
          DeptJLNY: {
            type: "string",
            id: 15
          },
          DeptDirector: {
            type: "string",
            id: 16
          },
          DeptDescription: {
            type: "string",
            id: 17
          },
          DeptEstate: {
            type: "int32",
            id: 18
          },
          DeptCreateDate: {
            type: ".bcl.DateTime",
            id: 19
          },
          DeptUpdateDate: {
            type: ".bcl.DateTime",
            id: 20
          },
          OriID: {
            type: "string",
            id: 21
          },
          School: {
            type: "School",
            id: 23
          },
          ParentDepartment: {
            type: "Department",
            id: 24
          },
          Campuses: {
            type: "Campuses",
            id: 25
          }
        }
      },
      Device: {
        fields: {
          DeviceID: {
            type: "string",
            id: 1
          },
          ModelID: {
            type: "string",
            id: 2
          },
          SchoolID: {
            type: "string",
            id: 3
          },
          UserID: {
            type: "string",
            id: 4
          },
          RoomID: {
            type: "string",
            id: 5
          },
          SupplierID: {
            type: "string",
            id: 6
          },
          DeviceCode: {
            type: "string",
            id: 7
          },
          DeviceName: {
            type: "string",
            id: 8
          },
          DeviceEnName: {
            type: "string",
            id: 9
          },
          DeviceXXDWCCM: {
            type: "string",
            id: 10
          },
          DevicePropertyCode: {
            type: "string",
            id: 11
          },
          DeviceUseCode: {
            type: "string",
            id: 12
          },
          DeviceTypeCode: {
            type: "string",
            id: 13
          },
          DeviceModel: {
            type: "string",
            id: 14
          },
          DevicePRODDate: {
            type: ".bcl.DateTime",
            id: 15
          },
          DeviceAreaCode: {
            type: "string",
            id: 16
          },
          DeviceJFKMM: {
            type: "string",
            id: 17
          },
          DeviceAcquiredDate: {
            type: ".bcl.DateTime",
            id: 18
          },
          DeviceManufacturer: {
            type: "string",
            id: 19
          },
          DeviceSerialNo: {
            type: "string",
            id: 20
          },
          DeviceInvoices: {
            type: "string",
            id: 21
          },
          DevicePic: {
            type: "string",
            id: 22
          },
          DeviceLocale: {
            type: "string",
            id: 23
          },
          DeviceQualification: {
            type: "string",
            id: 24
          },
          DeviceConfig: {
            type: "string",
            id: 25
          },
          DeviceCurrency: {
            type: "string",
            id: 26
          },
          DevicePrince: {
            type: ".bcl.Decimal",
            id: 27
          },
          DeviceIllustrate: {
            type: "string",
            id: 28
          },
          DeviceFinancialAccount: {
            type: "string",
            id: 29
          },
          DeviceExpiryDate: {
            type: ".bcl.DateTime",
            id: 30
          },
          DeviceSource: {
            type: "string",
            id: 31
          },
          DevicePrecious: {
            type: "int32",
            id: 32
          },
          DeviceStatusCode: {
            type: "string",
            id: 33
          },
          DeviceUse: {
            type: "int32",
            id: 34
          },
          DeviceEstate: {
            type: "int32",
            id: 35
          },
          DeviceDescription: {
            type: "string",
            id: 36
          },
          DeviceCreateDate: {
            type: ".bcl.DateTime",
            id: 37
          },
          DeviceUpdateDate: {
            type: ".bcl.DateTime",
            id: 38
          },
          DeviceType: {
            type: "int32",
            id: 39
          },
          ATID: {
            type: "string",
            id: 40
          },
          Model: {
            type: "Model",
            id: 42
          },
          School: {
            type: "School",
            id: 43
          },
          ClsRoom: {
            type: "ClsRoom",
            id: 45
          },
          Supplier: {
            type: "Supplier",
            id: 46
          },
          AssetType: {
            type: "AssetType",
            id: 54
          }
        }
      },
      Model: {
        fields: {
          ModelID: {
            type: "string",
            id: 1
          },
          BrandID: {
            type: "string",
            id: 2
          },
          ModelName: {
            type: "string",
            id: 3
          },
          ModelCode: {
            type: "string",
            id: 4
          },
          ModelPriority: {
            type: "int32",
            id: 5
          },
          ModelEstate: {
            type: "int32",
            id: 6
          },
          ModelDescription: {
            type: "string",
            id: 7
          },
          ModelCreateDate: {
            type: ".bcl.DateTime",
            id: 8
          },
          ModelUpdateDate: {
            type: ".bcl.DateTime",
            id: 9
          },
          CMDSID: {
            type: "string",
            id: 10
          },
          DeviceType: {
            type: "int32",
            id: 11
          },
          Brand: {
            type: "Brand",
            id: 13
          }
        }
      },
      RBConfig: {
        fields: {
          RBConfigID: {
            type: "string",
            id: 1
          },
          ClsRoomID: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          RECType: {
            type: "int32",
            id: 4
          },
          WISDIP: {
            type: "string",
            id: 5
          },
          WISDPort: {
            type: "int32",
            id: 6
          },
          WISDUID: {
            type: "string",
            id: 7
          },
          WISDPwd: {
            type: "string",
            id: 8
          },
          RTSPURL: {
            type: "string",
            id: 9
          },
          RTSPURL1: {
            type: "string",
            id: 10
          },
          RTSPURL2: {
            type: "string",
            id: 11
          },
          ClsRoom: {
            type: "ClsRoom",
            id: 13
          },
          Device: {
            type: "Device",
            id: 14
          }
        }
      },
      RBModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          RecTaskID: {
            type: "string",
            id: 2
          },
          TerminalSN: {
            type: "string",
            id: 3
          },
          RecTaskName: {
            type: "string",
            id: 4
          },
          LiveTaskID: {
            type: "string",
            id: 5
          },
          LiveKey: {
            type: "string",
            id: 6
          },
          LiveKey1: {
            type: "string",
            id: 7
          },
          LiveKey2: {
            type: "string",
            id: 8
          },
          LiveStartTime: {
            type: ".bcl.DateTime",
            id: 9
          },
          LiveReaderCount: {
            type: "int32",
            id: 10
          },
          RecStartTime: {
            type: ".bcl.DateTime",
            id: 11
          },
          RecEndTime: {
            type: ".bcl.DateTime",
            id: 12
          },
          RBConfig: {
            type: "RBConfig",
            id: 13
          },
          LiveEndTime: {
            type: ".bcl.DateTime",
            id: 14
          },
          ClsRoomName: {
            type: "string",
            id: 15
          },
          RECEstate: {
            type: "int32",
            id: 16
          },
          LiveEstate: {
            type: "int32",
            id: 17
          },
          RecType: {
            type: "int32",
            id: 18
          },
          IP: {
            type: "string",
            id: 19
          },
          Brand: {
            type: "string",
            id: 20
          },
          BrandCode: {
            type: "string",
            id: 21
          },
          Model: {
            type: "string",
            id: 22
          },
          ModelCode: {
            type: "string",
            id: 23
          },
          ErrCode: {
            type: "int32",
            id: 24
          },
          RecrdingTime: {
            type: "int32",
            id: 25
          },
          ScheduleInfo: {
            type: "string",
            id: 26
          },
          RecKey: {
            type: "string",
            id: 27
          },
          RecKey1: {
            type: "string",
            id: 28
          },
          RecKey2: {
            type: "string",
            id: 29
          },
          AutoPublish: {
            type: "bool",
            id: 30
          },
          LiveMode: {
            type: "int32",
            id: 31
          },
          PushURL: {
            rule: "repeated",
            type: "string",
            id: 32
          },
          DistrictLiveID: {
            type: "int64",
            id: 33
          }
        }
      },
      School: {
        fields: {
          SchoolID: {
            type: "string",
            id: 1
          },
          SchoolName: {
            type: "string",
            id: 2
          },
          SchoolEnName: {
            type: "string",
            id: 3
          },
          SchoolAddr: {
            type: "string",
            id: 4
          },
          SchoolPostCode: {
            type: "string",
            id: 5
          },
          SchoolXZQHM: {
            type: "string",
            id: 6
          },
          SchoolJXNY: {
            type: "string",
            id: 7
          },
          SchoolDay: {
            type: "string",
            id: 8
          },
          SchoolTypeCode: {
            type: "string",
            id: 9
          },
          SchoolOrganizerCode: {
            type: "string",
            id: 10
          },
          SchoolZGBMM: {
            type: "string",
            id: 11
          },
          SchoolXXXZM: {
            type: "string",
            id: 12
          },
          SchoolFDDBRH: {
            type: "string",
            id: 13
          },
          SchoolFRZSH: {
            type: "string",
            id: 14
          },
          SchoolChancellorNo: {
            type: "string",
            id: 15
          },
          SchoolChancelloName: {
            type: "string",
            id: 16
          },
          SchoolDWFZRH: {
            type: "string",
            id: 17
          },
          SchoolOrgCode: {
            type: "string",
            id: 18
          },
          SchoolTEL: {
            type: "string",
            id: 19
          },
          SchoolFax: {
            type: "string",
            id: 20
          },
          SchoolMail: {
            type: "string",
            id: 21
          },
          SchoolSite: {
            type: "string",
            id: 22
          },
          SchoolHistory: {
            type: "string",
            id: 23
          },
          SchoolLongitude: {
            type: ".bcl.Decimal",
            id: 24
          },
          SchoolLatitude: {
            type: ".bcl.Decimal",
            id: 25
          },
          SchoolShortName: {
            type: "string",
            id: 26
          },
          SchoolSummary: {
            type: "string",
            id: 27
          },
          SchoolDetails: {
            type: "string",
            id: 28
          },
          SchoolEstate: {
            type: "int32",
            id: 29
          },
          SchoolCreateDate: {
            type: ".bcl.DateTime",
            id: 30
          },
          SchoolUpdateDate: {
            type: ".bcl.DateTime",
            id: 31
          },
          DogID: {
            type: "string",
            id: 32
          }
        }
      },
      Supplier: {
        fields: {
          SupplierID: {
            type: "string",
            id: 1
          },
          SchoolID: {
            type: "string",
            id: 2
          },
          SupplierName: {
            type: "string",
            id: 3
          },
          SupplierAddr: {
            type: "string",
            id: 4
          },
          SupplierContact: {
            type: "string",
            id: 5
          },
          SupplierTEL: {
            type: "string",
            id: 6
          },
          SupplierMail: {
            type: "string",
            id: 7
          },
          SupplierFax: {
            type: "string",
            id: 8
          },
          SupplierPostal: {
            type: "string",
            id: 9
          },
          SupplierEstate: {
            type: "int32",
            id: 10
          },
          SupplierCreateDate: {
            type: ".bcl.DateTime",
            id: 11
          },
          SupplierUpdateDate: {
            type: ".bcl.DateTime",
            id: 12
          },
          SupplierDescription: {
            type: "string",
            id: 13
          },
          School: {
            type: "School",
            id: 15
          }
        }
      }
    }
  },
  TerminalInfoOne: {
    nested: {
      ModuleState: {
        values: {
          Off: 0,
          On: 1
        }
      },
      PortInfo: {
        fields: {
          ID: {
            type: "int32",
            id: 1
          },
          Name: {
            type: "string",
            id: 2
          },
          State: {
            type: "ModuleState",
            id: 3
          }
        }
      },
      PowerInfo: {
        fields: {
          ID: {
            type: "int32",
            id: 1
          },
          Name: {
            type: "string",
            id: 2
          },
          Value: {
            type: "ProjectorScreenState",
            id: 3
          },
          BoxID: {
            type: "int32",
            id: 4
          },
          IsPower: {
            type: "bool",
            id: 5
          },
          name: {
            type: "string",
            id: 6
          }
        }
      },
      ProjectorInfo: {
        fields: {
          State: {
            type: "ModuleState",
            id: 1
          },
          UsedTime: {
            type: "int32",
            id: 2
          },
          WarningTime: {
            type: "int32",
            id: 3
          }
        }
      },
      ProjectorScreenState: {
        values: {
          Off: 0,
          On: 1,
          Stop: 2,
          Hoist_Stop: 3,
          Drop_Stop: 4
        }
      },
      TerminalInfoModel: {
        fields: {
          APIVer: {
            type: "int32",
            id: 1
          },
          Brand: {
            type: "string",
            id: 2
          },
          Model: {
            type: "string",
            id: 3
          },
          SerialNo: {
            type: "string",
            id: 4
          },
          IP: {
            type: "string",
            id: 5
          },
          State: {
            type: "TerminalState",
            id: 6
          },
          PowerInfos: {
            rule: "repeated",
            type: "PowerInfo",
            id: 7
          },
          Projectors: {
            rule: "repeated",
            type: "ProjectorInfo",
            id: 8
          },
          PanelLock: {
            type: "ModuleState",
            id: 9
          },
          ELock: {
            type: "ModuleState",
            id: 10
          },
          Arming: {
            type: "ModuleState",
            id: 11
          },
          Guard: {
            type: "ModuleState",
            id: 12
          },
          VideoIns: {
            rule: "repeated",
            type: "ModuleState",
            id: 13,
            options: {
              packed: false
            }
          },
          VideoOuts: {
            rule: "repeated",
            type: "int32",
            id: 14,
            options: {
              packed: false
            }
          },
          VideoSource: {
            type: "int32",
            id: 15
          },
          VideoScene: {
            type: "int32",
            id: 16
          },
          TotalVolume: {
            type: "int32",
            id: 17
          },
          MicVolume: {
            type: "int32",
            id: 18
          },
          SoundVolume: {
            type: "int32",
            id: 19
          },
          IOIn: {
            rule: "repeated",
            type: "ModuleState",
            id: 20,
            options: {
              packed: false
            }
          },
          IOOut: {
            rule: "repeated",
            type: "ModuleState",
            id: 21,
            options: {
              packed: false
            }
          },
          Relay: {
            rule: "repeated",
            type: "ModuleState",
            id: 22,
            options: {
              packed: false
            }
          },
          ClsRoomID: {
            type: "string",
            id: 23
          },
          CampusesID: {
            type: "string",
            id: 24
          },
          BuildingID: {
            type: "string",
            id: 25
          },
          ClsRoomName: {
            type: "string",
            id: 26
          },
          ClsRoomTypeName: {
            type: "string",
            id: 27
          },
          RECState: {
            type: "int32",
            id: 28
          },
          RECStart: {
            type: ".bcl.DateTime",
            id: 29
          },
          GuidedMode: {
            type: "int32",
            id: 30
          },
          GuideLayout: {
            type: "int32",
            id: 31
          },
          TPC: {
            type: "int32",
            id: 32
          },
          TPCF: {
            type: "int32",
            id: 33
          },
          TPCURL: {
            type: "string",
            id: 34
          },
          SPC: {
            type: "int32",
            id: 35
          },
          SPCF: {
            type: "int32",
            id: 36
          },
          SPCURL: {
            type: "string",
            id: 37
          },
          TCC: {
            type: "int32",
            id: 38
          },
          TCCF: {
            type: "int32",
            id: 39
          },
          TCCURL: {
            type: "string",
            id: 40
          },
          SCC: {
            type: "int32",
            id: 41
          },
          SCCF: {
            type: "int32",
            id: 42
          },
          SCCURL: {
            type: "string",
            id: 43
          },
          CC: {
            type: "int32",
            id: 44
          },
          CCF: {
            type: "int32",
            id: 45
          },
          CCURL: {
            type: "string",
            id: 46
          },
          HDMI: {
            type: "int32",
            id: 47
          },
          HDMIF: {
            type: "int32",
            id: 48
          },
          Android: {
            type: "int32",
            id: 49
          },
          AndroidF: {
            type: "int32",
            id: 50
          },
          CoursewareF: {
            type: "int32",
            id: 51
          },
          LastUpateTimer: {
            type: ".bcl.DateTime",
            id: 52
          },
          ProVer: {
            type: "string",
            id: 53
          },
          Launcher: {
            type: "bool",
            id: 54
          },
          ChargeState: {
            type: "int32",
            id: 55
          },
          HDMIPort: {
            rule: "repeated",
            type: "PortInfo",
            id: 56
          },
          CPUUseRate: {
            type: "int32",
            id: 57
          },
          CPUTemperature: {
            type: "float",
            id: 58
          },
          FlashUsedRate: {
            type: "int32",
            id: 59
          },
          MemoryUsedRate: {
            type: "int32",
            id: 60
          },
          RECStartTimestamp: {
            type: "int64",
            id: 61
          },
          PanelStatus: {
            type: "bool",
            id: 62
          },
          CardReader: {
            type: "int32",
            id: 63
          },
          ConnectionName: {
            type: "string",
            id: 64
          }
        }
      },
      TerminalState: {
        values: {
          Shutdown: 0,
          OffLine: -1,
          ShuttingDown: 1,
          Opening: 2,
          Opened: 3
        }
      }
    }
  },
  TerminalInfo: {
    nested: {
      TerminalInfoModelDto: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          ClsRoomName: {
            type: "string",
            id: 2
          },
          CampusesID: {
            type: "string",
            id: 3
          },
          BuildingID: {
            type: "string",
            id: 4
          },
          ClsRoomTypeName: {
            type: "string",
            id: 5
          },
          IP: {
            type: "string",
            id: 6
          },
          SerialNo: {
            type: "string",
            id: 7
          },
          State: {
            type: "TerminalState",
            id: 8
          },
          PowerInfos: {
            rule: "repeated",
            type: "PowerInfo",
            id: 9
          },
          Projectors: {
            rule: "repeated",
            type: "ProjectorInfo",
            id: 10
          },
          Guard: {
            type: "ModuleState",
            id: 11
          },
          VideoIns: {
            rule: "repeated",
            type: "ModuleState",
            id: 12,
            options: {
              packed: false
            }
          },
          VideoSource: {
            type: "int32",
            id: 13
          },
          RECState: {
            type: "int32",
            id: 14
          }
        }
      },
      ModuleState: {
        values: {
          Off: 0,
          On: 1
        }
      },
      PowerInfo: {
        fields: {
          ID: {
            type: "int32",
            id: 1
          },
          Value: {
            type: "ProjectorScreenState",
            id: 3
          },
          BoxID: {
            type: "int32",
            id: 4
          },
          IsPower: {
            type: "bool",
            id: 5
          },
          name: {
            type: "string",
            id: 6
          }
        }
      },
      ProjectorInfo: {
        fields: {
          State: {
            type: "ModuleState",
            id: 1
          },
          UsedTime: {
            type: "int32",
            id: 2
          },
          WarningTime: {
            type: "int32",
            id: 3
          }
        }
      },
      ProjectorScreenState: {
        values: {
          Off: 0,
          On: 1,
          Stop: 2,
          Hoist_Stop: 3,
          Drop_Stop: 4
        }
      },
      TerminalState: {
        values: {
          Shutdown: 0,
          OffLine: -1,
          ShuttingDown: 1,
          Opening: 2,
          Opened: 3
        }
      }
    }
  },
  IOTAirModel: {
    nested: {
      ClsAirSwitchModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          iOTAirSwitches: {
            rule: "repeated",
            type: "IOTAirSwitchModel",
            id: 2
          }
        }
      },
      IOTAirSwitchModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          DevType: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          IP: {
            type: "string",
            id: 4
          },
          Port: {
            type: "int32",
            id: 5
          },
          Name: {
            type: "string",
            id: 6
          },
          Address: {
            type: "int32",
            id: 7
          },
          States: {
            type: "int32",
            id: 8
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 9
          },
          OnLine: {
            type: "bool",
            id: 10
          }
        }
      }
    }
  },
  Gate: {
    nested: {
      GateMagnetismModel: {
        fields: {
          SNo: {
            type: "int64",
            id: 1
          },
          Gate_1: {
            type: "int32",
            id: 2
          },
          Gate_2: {
            type: "int32",
            id: 3
          },
          Gate_3: {
            type: "int32",
            id: 4
          },
          Gate_4: {
            type: "int32",
            id: 5
          },
          CtrlType1: {
            type: "int32",
            id: 11
          },
          CtrlType2: {
            type: "int32",
            id: 12
          },
          CtrlType3: {
            type: "int32",
            id: 13
          },
          CtrlType4: {
            type: "int32",
            id: 14
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 6
          },
          IsOnline: {
            type: "bool",
            id: 15
          }
        }
      }
    }
  },
  IOTGWModel: {
    nested: {
      IOTAirSwitchModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          DevType: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          IP: {
            type: "string",
            id: 4
          },
          Port: {
            type: "int32",
            id: 5
          },
          Name: {
            type: "string",
            id: 6
          },
          Address: {
            type: "int32",
            id: 7
          },
          States: {
            type: "int32",
            id: 8
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 9
          },
          OnLine: {
            type: "bool",
            id: 10
          }
        }
      },
      IOTAmmeterModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          DevType: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          IP: {
            type: "string",
            id: 4
          },
          Name: {
            type: "string",
            id: 5
          },
          Address: {
            type: "int32",
            id: 6
          },
          Phase: {
            type: "int32",
            id: 7
          },
          InitCharge: {
            type: "float",
            id: 8
          },
          Voltage: {
            rule: "repeated",
            type: "float",
            id: 9,
            options: {
              packed: false
            }
          },
          ElectricCurrent: {
            rule: "repeated",
            type: "float",
            id: 10,
            options: {
              packed: false
            }
          },
          TotalCurrent: {
            type: "float",
            id: 11
          },
          KW: {
            rule: "repeated",
            type: "float",
            id: 12,
            options: {
              packed: false
            }
          },
          TotalKW: {
            type: "float",
            id: 13
          },
          ReactivePower: {
            rule: "repeated",
            type: "float",
            id: 14,
            options: {
              packed: false
            }
          },
          PowerFactor: {
            rule: "repeated",
            type: "float",
            id: 15,
            options: {
              packed: false
            }
          },
          Frequency: {
            type: "float",
            id: 16
          },
          KWH: {
            type: "float",
            id: 17
          },
          kvarh: {
            type: "float",
            id: 18
          },
          PowerWarn: {
            type: "int32",
            id: 19
          },
          EnergyWarnValue: {
            type: "double",
            id: 20
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 21
          },
          OnLine: {
            type: "bool",
            id: 22
          }
        }
      },
      IOTGWModel: {
        fields: {
          IP: {
            type: "string",
            id: 1
          },
          MAC: {
            type: "string",
            id: 2
          },
          ID: {
            type: "string",
            id: 3
          },
          DeviceID: {
            type: "string",
            id: 4
          },
          ClsRoomID: {
            type: "string",
            id: 5
          },
          ClsRoomName: {
            type: "string",
            id: 6
          },
          SceneNum: {
            type: "int32",
            id: 7
          },
          DimWhite: {
            type: "int32",
            id: 8
          },
          DimYellow: {
            type: "int32",
            id: 9
          },
          State: {
            type: "bool",
            id: 10
          },
          DeviceList: {
            rule: "repeated",
            type: "IoTDevice",
            id: 11
          },
          IOTSensorModel: {
            type: "IOTSensorModel",
            id: 12
          },
          IOTAmmeterModels: {
            rule: "repeated",
            type: "IOTAmmeterModel",
            id: 13
          },
          IOTAirSwitchModels: {
            rule: "repeated",
            type: "IOTAirSwitchModel",
            id: 14
          }
        }
      },
      IOTSensorModel: {
        fields: {
          ClsRoomID: {
            type: "string",
            id: 1
          },
          DevType: {
            type: "string",
            id: 2
          },
          DeviceID: {
            type: "string",
            id: 3
          },
          SensorIP: {
            type: "string",
            id: 4
          },
          SensorChannel: {
            type: "int32",
            id: 5
          },
          SensorHumidity: {
            type: "double",
            id: 6
          },
          SensorHumidityLevel: {
            type: "int32",
            id: 7
          },
          SensorHumidityLevelName: {
            type: "string",
            id: 8
          },
          SensorTemperature: {
            type: "double",
            id: 9
          },
          SensorTemperatureLevel: {
            type: "int32",
            id: 10
          },
          SensorTemperatureLevelName: {
            type: "string",
            id: 11
          },
          SensorLUX: {
            type: "int32",
            id: 12
          },
          SensorLUXLevel: {
            type: "int32",
            id: 13
          },
          SensorLUXLevelName: {
            type: "string",
            id: 14
          },
          SensorPM25: {
            type: "int32",
            id: 15
          },
          SensorPM25Level: {
            type: "int32",
            id: 16
          },
          SensorPM25LevelName: {
            type: "string",
            id: 17
          },
          SensorPM10: {
            type: "int32",
            id: 18
          },
          SensorPM10Level: {
            type: "int32",
            id: 19
          },
          SensorPM10LevelName: {
            type: "string",
            id: 20
          },
          SensorCO2: {
            type: "int32",
            id: 21
          },
          SensorCO2Level: {
            type: "int32",
            id: 22
          },
          SensorCO2LevelName: {
            type: "string",
            id: 23
          },
          SensorCH2O: {
            type: "double",
            id: 24
          },
          SensorCH2OLevel: {
            type: "int32",
            id: 25
          },
          SensorCH2OLevelName: {
            type: "string",
            id: 26
          },
          SensorTVOC: {
            type: "double",
            id: 27
          },
          SensorTVOCLevel: {
            type: "int32",
            id: 28
          },
          SensorTVOCLevelName: {
            type: "string",
            id: 29
          },
          UpdateTime: {
            type: ".bcl.DateTime",
            id: 30
          },
          OnLine: {
            type: "bool",
            id: 31
          }
        }
      },
      IoTDevice: {
        fields: {
          IP: {
            type: "string",
            id: 1
          },
          ID: {
            type: "string",
            id: 2
          },
          Type: {
            type: "string",
            id: 3
          },
          State: {
            type: "bool",
            id: 4
          },
          on_1: {
            type: "bool",
            id: 5
          },
          on_2: {
            type: "bool",
            id: 6
          },
          on_3: {
            type: "bool",
            id: 7
          },
          white: {
            type: "int32",
            id: 8
          },
          yellow: {
            type: "int32",
            id: 9
          },
          MotorState: {
            type: "int32",
            id: 10
          },
          FanState: {
            type: "int32",
            id: 11
          },
          VPStates: {
            rule: "repeated",
            type: "IoTVPState",
            id: 12
          },
          IOTGWIP: {
            type: "string",
            id: 14
          },
          DoorSensorOpen: {
            type: "bool",
            id: 15
          },
          DoorSensorAbnormal: {
            type: "bool",
            id: 16
          },
          DoorLockNO: {
            type: "bool",
            id: 17
          },
          DoorLockSwitch: {
            type: "bool",
            id: 18
          },
          Battery: {
            type: "int32",
            id: 19
          }
        }
      },
      IoTVPState: {
        fields: {
          ID: {
            type: "string",
            id: 1
          },
          B0: {
            type: "int32",
            id: 2
          },
          B1: {
            type: "int32",
            id: 3
          },
          B2: {
            type: "int32",
            id: 4
          },
          B3: {
            type: "int32",
            id: 5
          },
          B4: {
            type: "int32",
            id: 6
          },
          B6: {
            type: "int32",
            id: 7
          }
        }
      }
    }
  },
  WS: {
    nested: {
      WSMessage: {
        fields: {
          ID: {
            type: "string",
            id: 1
          },
          Type: {
            type: "WSMessageType",
            id: 2
          },
          Parameters: {
            rule: "repeated",
            type: "MessageParameter",
            id: 3
          }
        }
      },
      MessageParameter: {
        fields: {
          Key: {
            type: "string",
            id: 1
          },
          Value1: {
            type: "int32",
            id: 2
          },
          Value2: {
            type: "string",
            id: 3
          },
          Value3: {
            type: "bool",
            id: 4
          },
          Value4: {
            type: "bytes",
            id: 5
          },
          Value5: {
            type: "double",
            id: 6
          },
          Value6: {
            type: "float",
            id: 7
          },
          Value7: {
            type: ".bcl.DateTime",
            id: 8
          },
          Value8: {
            type: "int64",
            id: 9
          },
          PB64StrValue: {
            type: "string",
            id: 10
          },
          PBTypeName: {
            type: "string",
            id: 11
          }
        }
      },
      WSMessageType: {
        values: {
          Connect: 0,
          KeepAlive: 1,
          JoinGroup: 10,
          QuitGroup: 11,
          ReceiveALLStatus: 1000,
          ReceiveStatus: 1001,
          ReceiveRBStatus: 1002,
          ReceiveALLRHGWStatus: 1003,
          ReceiveALLIOTStatus: 1004,
          UpdateAirSwitch: 1010,
          UpdateIoTGWDevState: 1011,
          UpdateGateMagnetismState: 1012
        }
      }
    }
  },
  Scan: {
    nested: {
      CtrlScanInfo: {
        fields: {
          ip: {
            type: "string",
            id: 1
          },
          serialNo: {
            type: "string",
            id: 2
          },
          time: {
            type: "int64",
            id: 3
          },
          type: {
            type: "int32",
            id: 4
          }
        }
      }
    }
  }
});

module.exports = $root;
